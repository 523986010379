import PieChart from './PieChart';
import './Tokenomics.css'
import AkamaruMain from '../assets/moondog.png';
import akuText from '../assets/akutext1.png';

export default function Tokenomics() {

  return (
    <div className="tokenomicsContainer" id="tokenomics">
      <div className='tokenomicsAkamaruImgAndText'>
        <img className="tokenomicsAkamaruImg" src={AkamaruMain} alt="Akamaru Main" />
        <img className="tokenomicsAkamaruText" src={akuText} alt="Akamaru Text" />
      </div>
      <PieChart />
    </div>
  );
}