import { formatAddress } from "./address.service";

export default class EligibilityHandler {
  static async getEligibleQuantity(adrs) {
    try {
      const response = await fetch(`https://akamaruapi.uk/getAmount?adrs=${formatAddress(adrs.toLowerCase())}`);
      // const response = await fetch(`http://localhost:3005/getAmount?adrs=${formatAddress(adrs.toLowerCase())}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data.amount;
    } catch (error) {
      console.error('Error fetching data:', error);
      return -1;
    }
  }

  static async getProof(adrs) {
    try {
      const response = await fetch(`https://akamaruapi.uk/getProof?adrs=${formatAddress(adrs.toLowerCase())}`);
      // const response = await fetch(`http://localhost:3005/getProof?adrs=${formatAddress(adrs.toLowerCase())}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data.proof;
    } catch (error) {
      console.error('Error fetching data:', error);
      return -1;
    }
  }

}