import React from 'react';
import './PieChart.css';
import { PieChart } from 'react-minimal-pie-chart';

const data = [

  { title: 'DEX Liquidity', value: 65, color: 'rgb(247, 149, 3)' },  // Tomato (near red)
  { title: 'Starknet Community Airdrop', value: 16, color: 'rgb(247, 121, 3)' },  // Light Salmon (near orange)
  { title: 'Staking Incentives', value: 7, color: 'rgb(247, 80, 3)' },  // Saddle Brown (brown)
  { title: 'Listings', value: 4, color: 'rgb(247, 40, 3)' },  // Gold (yellow)
  { title: 'The Starks NFT Holders Airdrop', value: 3, color: 'rgb(231, 172, 11)' },  // Chocolate (brown)
  { title: 'Team', value: 3, color: 'rgb(156, 92, 19)' },  // Orange Red (near red)
  { title: 'Community rewards', value: 2, color: 'rgb(228, 151, 9)' },  // Dark Orange (orange)
];

const DonutChartComponent = () => (
  <div className='pieChartAndOverviewContainer'>
    <div className='tokenomicsTitle'>Tokenomics</div>
    <div className='pieChartAndOverviewContainerAndAkuAnim'>
      <div className='tokenomicsOverviewContainer'>
        <div className='tokenomicsDescriptionTotalSuppLine'>Total supply : 10 000 000 000 000</div>
        <div className='tokenomicsDescriptionLine'>
          <div className='tokenomicsDescriptionLineValue'>0% tax</div>
          <div className='tokenomicsDescriptionLineValue'>Fair launched</div>
          <div className='tokenomicsDescriptionLineValue'>No contract ownership</div>
          <div className='tokenomicsDescriptionLineValue'>Liquidity locked</div>
        </div>
      </div>
      <div className='pieChartAndListContainer'>
        <PieChart
          data={data}
          style={{ height: '23rem', width: '23rem' }}
          animate
          radius={42}  // Adjust the radius for the size of the hole in the middle
          lineWidth={50}  // Adjust the lineWidth to create a thicker ring (optional)
        />
        <div className='PieChartListContainer'>
          {data.map((entry, index) => (
            <div className='listPieChartValuesAndBullets' key={index}>
              <li className='bulletslistPieChartValues' style={{ color: entry.color}}></li>
              <div className='listPieChartValues'>
                {entry.value}% {entry.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default DonutChartComponent;
