import './Art.css';
import React, { useRef, useState } from 'react';
import slideformore from '../assets/slideformore.png';
import art1 from '../assets/art/akus1.png';
import art2 from '../assets/art/akus2.png';
import art3 from '../assets/art/akus3.png';
import art4 from '../assets/art/akus4.png';
import art5 from '../assets/art/akus5.png';
import art6 from '../assets/art/akus6.png';
import art7 from '../assets/art/akus7.png';
import art8 from '../assets/art/akus8.png';
import art9 from '../assets/art/akus9.png';
import art10 from '../assets/art/akus10.png';
import art11 from '../assets/art/akus11.png';
import art12 from '../assets/art/akus12.png';
import art13 from '../assets/art/akus13.png';
import art14 from '../assets/art/akus14.png';
import art15 from '../assets/art/akus15.png';
import art16 from '../assets/art/akus16.png';
import art17 from '../assets/art/akus17.png';
import art18 from '../assets/art/akus18.png';
import art19 from '../assets/art/akus19.png';
import art20 from '../assets/art/akus20.png';
import art21 from '../assets/art/akus21.png';
import art22 from '../assets/art/akus22.png';
import art23 from '../assets/art/akus23.png';
import art24 from '../assets/art/akus24.png';
import art25 from '../assets/art/akus25.png';

import meme1 from '../assets/art/otherMemes/1.png';
import meme2 from '../assets/art/otherMemes/2.png';
import meme3 from '../assets/art/otherMemes/3.png';
import meme4 from '../assets/art/otherMemes/4.png';
import meme5 from '../assets/art/otherMemes/5.png';
import meme6 from '../assets/art/otherMemes/6.png';
import meme7 from '../assets/art/otherMemes/7.png';
import meme8 from '../assets/art/otherMemes/8.png';
import meme9 from '../assets/art/otherMemes/9.png';
import meme10 from '../assets/art/otherMemes/10.png';
import meme11 from '../assets/art/otherMemes/11.png';
import meme12 from '../assets/art/otherMemes/12.png';
import meme13 from '../assets/art/otherMemes/13.png';

import ImageDownloadButton from './ImageDownloadButton';

export default function Art() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // You can adjust the multiplier to control the scrolling speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const images = [art1, art2, art3, art4, art5, art6, art7, art8, art9, art10, art11, art12, art13, art14, art15, art16, art17, art18, art19, art20, art21, art22, art23, art24, art25, meme1, meme2, meme3, meme4, meme5, meme6, meme7, meme8, meme9, meme10, meme11, meme12, meme13];

  return (

    <div className="ArtAndDragForMoreContainer" id="art">
      <div className="downloadMemesAndSlideContainer">
        <ImageDownloadButton images={images} />
        <div className="slideForMoreImgContainer">
          <img className='slideForMoreImg' src={slideformore} alt='slideForMore' />
        </div>
      </div>

      <div
        ref={containerRef}
        className="ArtContainer"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="ArtImagesContainer">
          <img className="ArtImg" src={art7} alt="Akamaru Main" />
          <img className="ArtImg" src={art4} alt="Akamaru Main" />
          <img className="ArtImg" src={art6} alt="Akamaru Main" />
          <img className="ArtImg" src={art10} alt="Akamaru Main" />
          <img className="ArtImg" src={art8} alt="Akamaru Main" />
          <img className="ArtImg" src={art9} alt="Akamaru Main" />
          <img className="ArtImg" src={art12} alt="Akamaru Main" />
          <img className="ArtImg" src={art3} alt="Akamaru Main" />
          <img className="ArtImg" src={art1} alt="Akamaru Main" />
          <img className="ArtImg" src={art2} alt="Akamaru Main" />
          <img className="ArtImg" src={art14} alt="Akamaru Main" />
          <img className="ArtImg" src={art11} alt="Akamaru Main" />
          <img className="ArtImg" src={art13} alt="Akamaru Main" />
          <img className="ArtImg" src={art5} alt="Akamaru Main" />
          <img className="ArtImg" src={art15} alt="Akamaru Main" />
          <img className="ArtImg" src={art16} alt="Akamaru Main" />
          <img className="ArtImg" src={art17} alt="Akamaru Main" />
          <img className="ArtImg" src={art18} alt="Akamaru Main" />
          <img className="ArtImg" src={art19} alt="Akamaru Main" />
          <img className="ArtImg" src={art20} alt="Akamaru Main" />
          <img className="ArtImg" src={art21} alt="Akamaru Main" />
          <img className="ArtImg" src={art22} alt="Akamaru Main" />
          <img className="ArtImg" src={art23} alt="Akamaru Main" />
          <img className="ArtImg" src={art24} alt="Akamaru Main" />
          <img className="ArtImg" src={art25} alt="Akamaru Main" />
        </div>
      </div>
    </div>

  );
}
