import './Eligibility.css';
import React from "react";
import { useState, useEffect } from "react";

import logogecko from '../assets/logogecko.png';
import starknetLogo from '../assets/starknetlogo.png';

import twitterIcon from '../assets/xIconClaim.png';
import telegramIcon from '../assets/telegramIconClaim.png';
import websiteIcon from '../assets/websiteIconClaim.png';
import paraku from '../assets/paraku.png';
import parakuRed from '../assets/parakuRed.png';
import backarrow from '../assets/backarrowwhite.png';
import EligibilityHandler from '../lib/EligibilityHandler';

export default function Eligibility() {
  const [quantityIndex, setQuantityIndex] = useState(0);
  const [isEligiblePanel, setIsEligiblePanel] = useState(false);
  const [joinTheMovement, setJoinTheMovement] = useState(true);
  const [adrs, setAdrs] = useState('');



  const airdropQuantities = [18000000, 19000000, 20000000, 25000000, 35000000, 50000000, 100000000]

  const handleChangeAdrs = (event) => {
    let lowerCaseAdrs = event.target.value.toLowerCase();
    setAdrs(lowerCaseAdrs);
  };

  async function checkEligibility(address) {
    let quantity = await EligibilityHandler.getEligibleQuantity(address);
    if(quantity == undefined){
      setQuantityIndex(0);
      setIsEligiblePanel(true);
    }
    else {
      quantity = parseInt(quantity);
      setQuantityIndex(quantity);
      setIsEligiblePanel(true);
    }
  }

  // const tweetLink = "https://twitter.com/intent/tweet?text=I%20just%20my%20claimed%20my%20%24AKU%20airdrop!%0AIf%20you%27re%20eligible%20for%20the%20%24STRK%20airdrop%20you%20can%20claim%20some%20yourself.%0AJoin%20the%20movement!%20%40AkamaruStarknet%20%23Starknet"
  const tweetLink = "https://twitter.com/intent/tweet?text=I'm%20eligible%20for%20the%20%40AkamaruStarknet%20airdrop!%0ACheck%20your%20eligibility%20at%20akamarutoken.com%2Feligibility.%0AJoin%20the%20movement!%20$AKU%20$STRK%20%23Starknet"

  return (
    <div className="claimContainer">
      <div className="claimTitle">
        <img src={starknetLogo} className="starknetIdLogo" />
        <div className="claimTitleText">X</div>
        <img src={logogecko} className="akuLogoTitle" />
      </div>

      <div className="claimWalletAndText">
        {joinTheMovement &&
          <div className="JoinMovementSocialContainer">
            <div className='JoinMovementLinksContainer'>
              <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/AkamaruStarknet'>
                <img className='BottomLinksIcon' src={twitterIcon} alt='twitterIcon' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href='https://t.me/AkamaruStarknet'>
                <img className='BottomLinksIcon' src={telegramIcon} alt='telegramIcon' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href='https://akamarutoken.com'>
                <img className='BottomLinksIcon' src={websiteIcon} alt='websiteIcon' />
              </a>
            </div>
            <div className="joinMovementTitle">
              Join the movement!
            </div>
            <div className="joinMovementDescription">
              <div>- Follow us on Twitter</div>
              <div>- Join our Telegram</div>
              <div>- Visit our website</div>
            </div>
            <div className="doneJoinMovementButton" onClick={() => setJoinTheMovement(false)}>
              Done
            </div>
          </div>
        }
        {isEligiblePanel &&
          <div className="successfulClaimContainer">
            <img src={backarrow} className="successfulClaimbackArrow" onClick={() => setIsEligiblePanel(false)} />
            <img src={quantityIndex > 0 ? paraku : parakuRed} className="successfulClaimParakuImg" />
            {quantityIndex > 0 &&
              <div className="successfulClaimTextAndTweetIt">
                <div className="successfulClaimTextAndQuantity">
                  <div className="successfulClaimText">
                    Eligible!
                  </div>
                  <div>For {airdropQuantities[quantityIndex - 1]} AKU</div>
                </div>
                <a target="_blank" rel="noopener noreferrer" href={tweetLink} className="tweetItContainer">
                  <div className="tweetText">
                    Tweet it!
                  </div>
                  <img className='TweetItIcon' src={twitterIcon} alt='twitterIcon' />              
                </a>
              </div>
              
            }
            {quantityIndex == 0 &&
              <div className="unsuccessfulClaimText">
                Not eligible.
              </div>
            }
            {quantityIndex == -1 &&
              <div className="unsuccessfulClaimText">
                Error when querying API.
              </div>
            }
          </div>
          
        }
        {!joinTheMovement && !isEligiblePanel &&
          <div className="claimWalletAndDisclaimerAndListContainer">
            <div>Claiming starts at 10 AM UTC.</div>
            <div className="claimWalletText">
              Input an address to check its eligibility
            </div>
            <input
              className="claimWalletInput"
              type="text" 
              value={adrs} 
              onChange={handleChangeAdrs} 
              placeholder="Address"
            />
            <div className="checkEligibilityButton" onClick={() => checkEligibility(adrs)}>
              Check
            </div>

          </div>
        }
      </div>
    </div>

  );
}