import './Presentation.css';
import AkamaruMain from '../assets/akusite3.png';
// import RedSun from '../assets/redSun.png';
import akamarutitle from '../assets/akamarutitle2.png';
import externalLinkIconOrange from '../assets/externalLinkIconOrange.png';
import externalLinkIconBlack from '../assets/externalLinkIconBlack.png';
import { useState } from 'react';

const why = "On Starknet, there is a tradition to name projects after anime characters. Many contributors, developers, and users adhere to this anime project culture. \n" +
"The ecosystem has Madara, Satoru, Beerus, Deoxys, Ekubo, Garaga, Tsubasa, Kakarot ... \n" +
"It now counts one more! Akamaru.";

export default function Presentation() {
  // const [externalImg, setExternalImg] = useState(externalLinkIconBlack);

  // const handleMouseEnter = () => {
  //   setExternalImg(externalLinkIconOrange);
  // };

  // const handleMouseLeave = () => {
  //   setExternalImg(externalLinkIconBlack);
  // };

  return (
    <div className="presentationContainer">
      <div className="presentationImagesContainer">
        <img className="presentationAkamaruImg" src={AkamaruMain} alt="Akamaru Main" />
      </div>
      <div className="presentationBigTitleAndText">
        {/* <div className="presentationBigTitle">AKAMARU</div> */}
        <img className="presentationBigTitle" src={akamarutitle} alt="Akamaru Title" />
        <div className='buyAndLearnMoreContainer'>
          {/* <a className='buyButton' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} href='https://app.avnu.fi/en?amount=0.001&tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=0x0137dfca7d96cdd526d13a63176454f35c691f55837497448fad352643cfe4d4' target="_blank" rel="noopener noreferrer"> */}
          <a className='buyButton' href='https://app.avnu.fi/en?amount=0.001&tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=0x0137dfca7d96cdd526d13a63176454f35c691f55837497448fad352643cfe4d4' target="_blank" rel="noopener noreferrer">
            <div>Buy on Avnu</div>
            <img className='externalLinkIcon' src={externalLinkIconBlack} alt='externalLinkIcon' />
          </a>
          <a className='learnMoreButton' href='https://t.me/AkamaruStarknet' target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </div>
        <div className="presentationText">
          <div className="presentationDescription">
            <div className="presentationTitle">A community first initiative</div>
            <div>Welcome to Akamaru, a community-driven meme token on a mission.</div>
            <div>Our objective is to reach the masses using our cute memey anime dog power.</div>
            {/* <div>
              We are committed to reward Starknet users. That's why we have planned airdrops for the Starknet communities, STRK eligible addresses, and all those who contribute to our vibrant ecosystem.
            </div> */}
          </div>
          
          <div className="presentationDescription">
            <div className="presentationTitle">Why Akamaru ?</div>
            <div className="presentationWhyDescription">{why}</div>
          </div>
          <div className='airdroppedCountsPresentationContainer'>
            <div className='airdroppedCountsPresentation'>
              <div className='airdroppedCountsPresentationNumber'>20000+</div>
              <div className='airdroppedCountsPresentationTitle'>Holders</div>
            </div>
            <div className='airdroppedCountsPresentation'>
              <div className='airdroppedCountsPresentationNumber'>40000+</div>
              <div className='airdroppedCountsPresentationTitle'>Airdropped</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
