import './Claim.css';
import React from "react";
import { useState, useEffect } from "react";
import {
  connectWallet,
  chainId,
  getProvider,
} from "../lib/wallet.service"

import { currentChain } from "../constants/chain"
import WalletConnect from '../Components/WalletConnect';
import Getter from '../lib/blockchain/Getter';
import Sender from '../lib/blockchain/Sender';
import logogecko from '../assets/logogecko.png';
import starknetLogo from '../assets/starknetlogo.png';
import paraku from '../assets/paraku.png';
import backarrow from '../assets/backarrowwhite.png';

import twitterIcon from '../assets/xIconClaim.png';
import telegramIcon from '../assets/telegramIconClaim.png';
import websiteIcon from '../assets/websiteIconClaim.png';
import MerkleTree from '../lib/MerkleTree';

import { formatAddress } from "../lib/address.service";
import EligibilityHandler from '../lib/EligibilityHandler';
import CountdownTimer from '../Components/CountdownTimer';


export default function Claim() {
  const [address, setAddress] = useState();
  const [chain, setChain] = useState();
  const [isConnected, setConnected] = useState(false);
  const [account, setAccount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasClaimedWallet, setHasClaimedWallet] = useState(false);
  const [hasClaimedStarted, setHasClaimedStarted] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [isEligible, setIsEligible] = useState(true);
  const [isClaiming, setIsClaimming] = useState(false);
  const [akuBalance, setAkuBalance] = useState("0");
  const [provider, setProvider] = useState();
  const [joinTheMovement, setJoinTheMovement] = useState(true);

  const [quantityIndex, setQuantityIndex] = useState(0);
  const [proof, setProof] = useState([]);

  const airdropQuantities = [18000000, 19000000, 20000000, 25000000, 35000000, 50000000, 100000000]

  async function setWalletInfos(wallet){
    setAddress(wallet.selectedAddress)

    let chainIdVal = chainId(wallet)
    
    setChain(chainIdVal)

    if(chainIdVal == currentChain){
      let providerVal = getProvider(chainIdVal)
      setProvider(providerVal)
      checkEligibility(wallet.selectedAddress)
      getOnChainInfos(wallet.selectedAddress, providerVal)
    }
    setConnected(wallet.isConnected)
    if (wallet.account) {
      setAccount(wallet.account)
    }
  }

  async function getOnChainInfos(selectedAddress, provider) {
    let promiseBalance = Getter.getAkuBalance(selectedAddress, provider)
    let promiseWalletClaimed = Getter.hasWalletClaimed(selectedAddress, provider)
    let promiseHasClaimStarted = Getter.hasClaimStarted(provider)
  
    let results = await Promise.all([promiseBalance, promiseWalletClaimed, promiseHasClaimStarted])
    setAkuBalance(results[0])
    setHasClaimedWallet(results[1])
    setHasClaimedStarted(results[2])
  }

  async function checkEligibility(address) {
    let quantity = await EligibilityHandler.getEligibleQuantity(address);
    let proof = await EligibilityHandler.getProof(address);
    if(quantity == -1 || proof == -1){
      setQuantityIndex(-1);
      setIsEligible(false);
    }
    if(quantity == 0){
      setQuantityIndex(0);
      setIsEligible(false);
    }
    else {
      quantity = parseInt(quantity);
      setQuantityIndex(quantity);
      setProof(proof);
      setIsEligible(true);
    }
  }

  async function handleConnectWallet() {
    setIsLoading(true)
    let wallet = await connectWallet()
    if(wallet) {
      await setWalletInfos(wallet)
    }
    setIsLoading(false)
  }

  async function handleClaim() {
    setIsClaimming(true)
    let leaf = MerkleTree.computeLeafHash([formatAddress(address.toLowerCase()), quantityIndex.toString()])
    let succes = await Sender.claim(account, provider, leaf, proof, quantityIndex)
    if(succes){
      let balance = await Getter.getAkuBalance(address, provider)
      setAkuBalance(balance)
      setHasClaimedWallet(true)
      setClaimSuccess(true)
    }
    setIsClaimming(false)
  }

  function makeClaimButton(isClaiming, isClaimed) {
    if(isClaimed){
      return (
        <div className="claimed">
          Claimed
        </div>
      )
    }
    if(isClaiming){
      return (
        <div className="claimButton">
          Claiming...
        </div>
      )
    }
    else {
      return (
        <div className="claimButton" onClick={() => handleClaim()}>
          Claim
        </div>
      )
    }

  }

  // if(isLoading){
  //   return (
  //     <div className="claimContainer">
  //       <div className="loadingWalletMsg">Loading...</div>
  //     </div>
  //   )
  // }

  const tweetLink = "https://twitter.com/intent/tweet?text=I%20just%20my%20claimed%20my%20%24AKU%20airdrop!%0AIf%20you%27re%20eligible%20for%20the%20%24STRK%20airdrop%20you%20can%20claim%20some%20yourself.%0AJoin%20the%20movement!%20%40AkamaruStarknet%20%23Starknet"


  return (
    <div className="claimContainer">
      <div className="claimTitle">
        <img src={starknetLogo} className="starknetIdLogo" />
        <div className="claimTitleText">X</div>
        <img src={logogecko} className="akuLogoTitle" />
      </div>

      <div className="claimWalletAndText">
        <div className="alreadyClaimedText">
          The airdrop has ended, all AKU's have been claimed.
        </div>
        {/* {joinTheMovement &&
          <div className="JoinMovementSocialContainer">
            <div className='JoinMovementLinksContainer'>
              <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/AkamaruStarknet'>
                <img className='BottomLinksIcon' src={twitterIcon} alt='twitterIcon' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href='https://t.me/AkamaruStarknet'>
                <img className='BottomLinksIcon' src={telegramIcon} alt='telegramIcon' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href='https://akamarutoken.com'>
                <img className='BottomLinksIcon' src={websiteIcon} alt='websiteIcon' />
              </a>
            </div>
            <div className="joinMovementTitle">
              Join the movement!
            </div>
            <div className="joinMovementDescription">
              <div>- Follow us on Twitter</div>
              <div>- Join our Telegram</div>
              <div>- Visit our website</div>
            </div>
            <div className="doneJoinMovementButton" onClick={() => setJoinTheMovement(false)}>
              Done
            </div>
          </div>
        }
        {claimSuccess &&
          <div className="successfulClaimContainer">
            <img src={backarrow} className="successfulClaimbackArrow" onClick={() => setClaimSuccess(false)} />
            <img src={paraku} className="successfulClaimParakuImg" />
            <div className="successfulClaimText">
              Claim successful
            </div>
            <a target="_blank" rel="noopener noreferrer" href={tweetLink} className="tweetItContainer">
              <div className="tweetText">
                Tweet it!
              </div>
              <img className='TweetItIcon' src={twitterIcon} alt='twitterIcon' />              
            </a>
          </div>
        }
        {!claimSuccess && !joinTheMovement &&
        <div className="claimWalletAndDisclaimerAndListContainer">
          <WalletConnect  isConnected={isConnected} chain={chain} address={address} handleConnectWallet={handleConnectWallet} />
          {isConnected && chain === currentChain && isEligible && hasClaimedStarted && quantityIndex != -1 &&
            <div className="claimDisclaimerAndListContainer">
              <div className='akuBalanceContainer'>
                <div className='akuBalance'>Balance :</div>
                <div className='akuBalanceValueImage'>
                  <div className='akuBalanceValue'>{akuBalance} AKU</div>
                </div>
              </div>
              {hasClaimedWallet &&
                <div className="alreadyClaimedText">
                  Wallet already claimed
                </div>
              }
              {!hasClaimedWallet &&
                <div className='akuGetAmountContainer'>
                  <div className='claimTextContainer'>
                    <div className='akuGetAmount'>Claim and get {airdropQuantities[quantityIndex - 1]} AKU</div>
                  </div>
                  {makeClaimButton(isClaiming, 0, hasClaimedWallet)}
                </div>
              }
              <div className="BottomSocialContainer">
                <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/AkamaruStarknet'>
                  <img className='BottomLinksIcon' src={twitterIcon} alt='twitterIcon' />
                </a>
                <a target="_blank" rel="noopener noreferrer" href='https://t.me/AkamaruStarknet'>
                  <img className='BottomLinksIcon' src={telegramIcon} alt='telegramIcon' />
                </a>
                <a target="_blank" rel="noopener noreferrer" href='https://akamarutoken.com'>
                  <img className='BottomLinksIcon' src={websiteIcon} alt='websiteIcon' />
                </a>
              </div>
            </div>
          }
          {isConnected && chain === currentChain && quantityIndex == -1 &&
            <div className="notEligible">
              Error when querying API.
            </div>
          }
          {isConnected && chain === currentChain && !isEligible && quantityIndex != -1 &&
            <div className="notEligible">
              You aren't eligible for the airdrop.
            </div>
          }
          {isConnected && chain === currentChain && !hasClaimedStarted &&
            <div className="claimNotStarted">
              <div>Claim starts at 10:00 AM UTC.</div>
              <CountdownTimer targetDate="2/20/2024 10:00:00 AM UTC" />
            </div>
          }
        </div>
        }  */}
      </div>
    </div>

  );
}