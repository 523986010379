import React, { useState, useEffect } from 'react';
import './MemeGenerator.css';
import paraku from '../assets/paraku.png';


function computeWidthAndHeightKeepAspectRatio(width, height, maxWidth, maxHeight) {
  const aspectRatio = width / height;

  let newWidth = width;
  let newHeight = height;

  if (width > maxWidth || height > maxHeight) {
    if (width > maxWidth && aspectRatio > 1) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else if (height > maxHeight) {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }
  }

  if (width < maxWidth && height < maxHeight) {
    if (aspectRatio > 1) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }
  }

  return { width: newWidth, height: newHeight };

}



function MemeGenerator() {

  const memesDictionnary = {
    1: {src:paraku, size: { width: 865, height: 1190 }},
    2: {src:paraku, size: { width: 865, height: 1190 }},
  }

  const [baseImage, setBaseImage] = useState(null);
  const [memes, setMemes] = useState([]);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [baseImageSize, setBaseImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeBaseImage = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const maxImageWidth = screenWidth * 0.97;
      const maxImageHeight = screenHeight * 0.9;

      if (baseImage) {
        const img = new Image();
        img.onload = function () {
          setBaseImageSize(computeWidthAndHeightKeepAspectRatio(img.width, img.height, maxImageWidth, maxImageHeight));
        };

        img.src = baseImage;
      }
    };

    resizeBaseImage();

    window.addEventListener('resize', resizeBaseImage);

    return () => {
      window.removeEventListener('resize', resizeBaseImage);
    };
  }, [baseImage]);

  const handleBaseImageUpload = (event) => {
    const image = event.target.files[0];
    setBaseImage(URL.createObjectURL(image));
  };

  const handleMemeSelect = (memeIndex) => {
    setSelectedMeme(memeIndex);
  };

  const handleBaseImageClick = (event) => {
    if (selectedMeme) {
      const newSize = computeWidthAndHeightKeepAspectRatio(memesDictionnary[selectedMeme].size.width, memesDictionnary[selectedMeme].size.height, baseImageSize.width / 10, baseImageSize.height / 10);
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - newSize.width / 2;
      const y = event.clientY - rect.top;
      
      setMemes([...memes, { id: selectedMeme, position: { x, y }, size: newSize} ]);
      setSelectedMeme(null);
    }
  };

  const handleMemeDrag = (index, event) => {
    const newX = event.clientX;
    const newY = event.clientY;
    setMemes(memes.map((meme, i) => (i === index ? { ...meme, position: { x: newX, y: newY } } : meme)));
  };

  const handleMemeResize = (index, event) => {
    const newWidth = event.clientX - memes[index].position.x;
    const newHeight = event.clientY - memes[index].position.y;
    setMemes(memes.map((meme, i) => (i === index ? { ...meme, size: { width: newWidth, height: newHeight } } : meme)));
  };

  return (
    <div className="MemeGenerator">
      <h1>Meme Editor</h1>
      <div className="base-image-container">
        <input type="file" onChange={handleBaseImageUpload} />
        {baseImage && (
          <img
            src={baseImage}
            alt="Base"
            onClick={handleBaseImageClick}
            style={{ width: baseImageSize.width, height: baseImageSize.height }}
          />
        )}
        {memes.map((meme, index) => (
          <div
            key={index}
            className="meme"
            style={{
              position: 'absolute',
              left: meme.position.x,
              top: meme.position.y,
              width: meme.size.width,
              height: meme.size.height
            }}
            draggable
            onDrag={(event) => handleMemeDrag(index, event)}
            onDragEnd={(event) => handleMemeDrag(index, event)}
            onDragOver={(event) => event.preventDefault()}
            onDragStart={(event) => event.preventDefault()}
            onResize={(event) => handleMemeResize(index, event)}
          >
            <img
              src={memesDictionnary[meme.id].src}
              alt={`Meme ${index}`}
              style={{ width: meme.size.width, height: meme.size.height}}
            />
          </div>
        ))}
      </div>
      <div className="memes-container">
        <h2>Memes</h2>
        <div className="memes-list">
          {/* Sample memes, replace with your own */}
          <img className="meme-sticker" src={memesDictionnary[1].src} alt="Meme 1" onClick={() => handleMemeSelect(1)} />
          <img className="meme-sticker" src={memesDictionnary[2].src} alt="Meme 2" onClick={() => handleMemeSelect(2)} />
        </div>
      </div>
    </div>
  );
}

export default MemeGenerator;
