import ProgressBar from './ProgressBar';
import './Roadmap.css';
import akulambo from '../assets/akulambo.png';
import { useState } from 'react';


export default function Roadmap() {
  const [hasTriggeredLamboAnim, setHasTriggeredLamboAnim] = useState(false);

  const imageStyle = {
    transform: hasTriggeredLamboAnim ? 'translateX(-100rem) translateY(20rem)' : 'translateX(0) translateY(0)',
    transition: 'transform 0.5s ease-in-out', // Add a smooth transition effect
  };


  return (
    <div className="RoadmapContainer" id="roadmap">
      {/* {!hasTriggeredLamboAnim && <img className="RoadmapAkuLambo" src={akulambo} style={imageStyle} alt="akulambo" onClick={() => setHasTriggeredLamboAnim(true)} /> } */}
      {!hasTriggeredLamboAnim && <img className="RoadmapAkuLambo" src={akulambo} style={imageStyle} alt="akulambo"/> }
      <div className="RoadmapTitle">
        Roadmap
      </div>
      <div className="RoadmapContent">
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            Airdrop to Starknet Communities and STRK eligible addresses
          </div>
          <ProgressBar percent={100} />
        </div>
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            Setup a multisig with trusted parties from the Starknet ecosystem
          </div>
          <ProgressBar percent={100} />
        </div>
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            NFT collection
          </div>
          <ProgressBar percent={100} />
        </div>
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            NFT staking
          </div>
          <ProgressBar percent={50} />
        </div>
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            Partnership with decentralized exchanges of Starknet
          </div>
          <ProgressBar percent={50} />
        </div>
        <div className="RoadmapContentAndProgressBarItem">
          <div className="RoadmapContentItem">
            Listing on a centralized exchange
          </div>
          <ProgressBar percent={40} />
        </div>



      </div>

    </div>
  );
}
