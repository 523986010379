import './Home.css';
import Navbar from "../Components/Navbar";
import Tokenomics from "../Components/Tokenomics";
import Presentation from '../Components/Presentation';
import Trade from '../Components/Trade';
import Roadmap from '../Components/Roadmap';
import Integrations from '../Components/Integrations';
import Art from '../Components/Art';
import Bottom from '../Components/Bottom';

export default function Home() {
  return (
      <div className="homeContainer">
        <div className="homeNavbarPresentation">
          <Navbar />
          <Presentation />
        </div>
        {/* <Trade /> */}
        <Tokenomics />
        <Roadmap />
        <Integrations />
        <Art />
        <Bottom />
      </div>
  );
}