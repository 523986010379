import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ percent }) => {
  return (
    <div className="progress-bar-container">
      
        {percent == 100 ?
          <div className="progress-bar-completed" style={{ width: `${percent}%` }}>
            <span className="progress-text">{"DONE"}</span>
          </div>
          :
          <div className="progress-bar" style={{ width: `${percent}%` }}>
            <span className="progress-text">{`${percent}%`}</span>
          </div>
        }
      
    </div>
  );
};

export default ProgressBar;