import React from 'react';
import JSZip from 'jszip';
import './ImageDownloadButton.css';

const ImageDownloadButton = ({ images }) => {
  const handleDownload = async () => {
    try {
      const zip = new JSZip();

      await Promise.all(
        images.map(async (image, index) => {
          const blob = await fetch(image).then(response => response.blob());
          zip.file(`image_${index}.png`, blob);
        })
      );

      zip.generateAsync({ type: 'blob' }).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'images.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error('Error downloading the images:', error);
    }
  };

  return (
    <button className="buttonDownLoadMemes" onClick={handleDownload}>Download Memes</button>
  );
};
export default ImageDownloadButton;
