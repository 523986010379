import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import Home from './Pages/Home'
import Claim from './Pages/Claim';
import Eligibility from './Pages/Eligibility';
import MemeGenerator from './Pages/MemeGenerator';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/claim" element={<Claim />} />
        <Route path="/eligibility" element={<Eligibility />} />
        <Route path="/generator" element={<MemeGenerator />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
