import './Bottom.css';
import twitterIcon from '../assets/xIconOrange.png';
import telegramIcon from '../assets/telegramIconOrange.png';
import akamarutitle from '../assets/akamarutitle.png';
import akubottom from '../assets/akubottom.png';
import farcasterIconOrange from '../assets/farcasterIconOrange.png';

export default function Bottom() {
  return (
    <div className="BottomContainer">
      <div className='BottomInfosAndImgContainer'>
        <div className="BottomInfos">
          <div className="BottomTitle">
            <img className="BottomTitleImg" src={akamarutitle} alt="Akamaru Title" />
          </div>
          <div className='BottomSocialsContainer'>
            <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/AkamaruStarknet'>
              <img className='BottomSocialsIcon' src={twitterIcon} alt='twitterIcon' />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='https://warpcast.com/akamaru'>
              <img className='BottomSocialsIcon' src={farcasterIconOrange} alt='farcasterIcon' />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='https://t.me/AkamaruStarknet'>
              <img className='BottomSocialsIcon' src={telegramIcon} alt='telegramIcon' />
            </a>
          </div>
        </div>
        <img className="BottomImg" src={akubottom} alt="Akamaru Bottom" />
      </div>
      <div className="BottomText">
        © 2024 Akamaru • All Rights Reserved
      </div>
    </div>
  );
}