import './Integrations.css';
import braavosIcon from '../assets/braavos.png';
import argentIcon from '../assets/argent.png';
import jediswapLogoIcon from '../assets/jediswapLogo.png';
import nostraIcon from '../assets/nostra.png';
import ekuboIcon from '../assets/ekubo.png';
import avnuIcon from '../assets/avnu.png';
import fibrousIcon from '../assets/fibrous.png';


export default function Integrations() {
  return (
    <div className="IntegrationsContainer" id="integrations">
      <div className="IntegrationsTitle">
        Integrations
      </div>
      <div className="IntegrationsListAndTileContainer">
        {/* <div className="IntegrationsListTitle">
          Wallets
        </div> */}
        <div className="IntegrationsList">
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://braavos.app/">
              <img className="IntegrationsListItemIcon" src={braavosIcon} alt="Braavos" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Braavos</div>
              {/* <div className="IntegrationsListItemDescription">Store and monitor your AKUs</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://www.argent.xyz/">
              <img className="IntegrationsListItemIcon" src={argentIcon} alt="Argent" />
              <div className="IntegrationsListItemTitleAndDescription">
                <div className="IntegrationsListItemTitle">Argent</div>
                {/* <div className="IntegrationsListItemDescription">Store and monitor your AKUs</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://www.jediswap.xyz/">
              <img className="IntegrationsListItemIcon" src={jediswapLogoIcon} alt="JediSwap" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">JediSwap</div>
              {/* <div className="IntegrationsListItemDescription">Trade Akamaru</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://nostra.finance/">
              <img className="IntegrationsListItemIcon" src={nostraIcon} alt="Nostra" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Nostra</div>
              {/* <div className="IntegrationsListItemDescription">Trade Akamaru</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://ekubo.org/">
              <img className="IntegrationsListItemIcon" src={ekuboIcon} alt="Ekubo" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Ekubo</div>
              {/* <div className="IntegrationsListItemDescription">Trade Akamaru</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://app.avnu.fi/">
              <img className="IntegrationsListItemIcon" src={avnuIcon} alt="AVNU" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">AVNU</div>
              {/* <div className="IntegrationsListItemDescription">Trade Akamaru</div> */}
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://fibrous.finance/">
              <img className="IntegrationsListItemIcon" src={fibrousIcon} alt="Fibrous" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Fibrous</div>
              {/* <div className="IntegrationsListItemDescription">Trade Akamaru</div> */}
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="IntegrationsListAndTileContainer">
        <div className="IntegrationsListTitle">
          Decentralized Exchanges
        </div>
        <div className="IntegrationsList">
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://www.jediswap.xyz/">
              <img className="IntegrationsListItemIcon" src={jediswapLogoIcon} alt="JediSwap" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">JediSwap</div>
              <div className="IntegrationsListItemDescription">Trade Akamaru</div>
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://nostra.finance/">
              <img className="IntegrationsListItemIcon" src={nostraIcon} alt="Nostra" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Nostra</div>
              <div className="IntegrationsListItemDescription">Trade Akamaru</div>
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://ekubo.org/">
              <img className="IntegrationsListItemIcon" src={ekuboIcon} alt="Ekubo" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Ekubo</div>
              <div className="IntegrationsListItemDescription">Trade Akamaru</div>
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://app.avnu.fi/">
              <img className="IntegrationsListItemIcon" src={avnuIcon} alt="AVNU" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">AVNU</div>
              <div className="IntegrationsListItemDescription">Trade Akamaru</div>
              </div>
            </a>
          </div>
          <div className="IntegrationsListItem">
            <a target="_blank" rel="noopener noreferrer" className="IntegrationsListItemContainer" href="https://fibrous.finance/">
              <img className="IntegrationsListItemIcon" src={fibrousIcon} alt="Fibrous" />
              <div className="IntegrationsListItemTitleAndDescription">
              <div className="IntegrationsListItemTitle">Fibrous</div>
              <div className="IntegrationsListItemDescription">Trade Akamaru</div>
              </div>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}